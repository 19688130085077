import React from "react";
import classNames from "classnames";

function Paragraph({
  children,
  className,
  text14,
  text14Semibold,
  text12,
  text16,
  text18,
  text20,
  text28,
  text32,
}) {
  const classes = classNames({
    "xl:text-[32px] xl:leading-[46px] text-[28px] leading-[40px] font-semibold":
      text32,
    "xl:text-[28px] xl:leading-[36px] text-[24px] leading-[30px] font-bold":
      text28,
    "sm:text-[20px] text-[18px] leading-[26px] font-bold": text20,
    "text-[18px] leading-[24px] font-normal": text18,
    "text-[16px] leading-[21px] font-semibold": text16,
    "text-[14px] leading-[18px] font-normal": text14,
    "text-[14px] leading-[16px] font-semibold": text14Semibold,
    "text-[12px] leading-[16px] font-normal": text12,
  });
  return (
    <p className={`${classes} ${className ? className : ""}`}>{children}</p>
  );
}

export default Paragraph;
