import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { HiMiniSquares2X2 } from "react-icons/hi2";
import { FaUserGroup } from "react-icons/fa6";
import { IoPower } from "react-icons/io5";
import Modal from "../core/Modal";
import Paragraph from "../core/typography/Paragraph";
import Button from "../core/form-components/Button";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { paths } from "../../routes/path";

function SideBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const getNavItemClass = (path) => {
    return location.pathname === path
      ? "before:absolute before:block before:w-2 before:h-full before:rounded-[1px] before:-left-1 before:top-0 before:bg-primary-blue"
      : "";
  };
  return (
    <div className="lg:h-[100vh] overflow-y-auto relative">
      <div className="flex flex-col items-start gap-10 lg:pt-[90px] pt-0 lg:pb-0 pb-6">
        <ul
          className={`flex flex-col items-start lg:justify-center justify-start lg:w-full lg:static fixed top-0 transition-all duration-300 ease-in-out z-40 left-0 sm:w-1/2 w-full h-[calc(100vh-90px)]`}
        >
          <li
            className={`mb-[18px] block w-full px-2 relative ${getNavItemClass(
              "/dashboard"
            )}`}
          >
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                `inline-flex items-center justify-center xl:px-5 px-3.5 py-3.5 ${
                  isActive ? "text-primary-blue" : "text-light-grey"
                }`
              }
            >
              <HiMiniSquares2X2 fontSize={"24px"} />
            </NavLink>
          </li>
          <li
            className={`mb-[18px] block w-full px-2 relative ${getNavItemClass(
              "/usermanagement"
            )}`}
          >
            <NavLink
              to="/usermanagement"
              className={({ isActive }) =>
                `inline-flex items-center justify-center xl:px-5 px-3.5 py-3.5 ${
                  isActive ? "text-primary-blue" : "text-light-grey"
                }`
              }
            >
              <FaUserGroup fontSize={"24px"} />
            </NavLink>
          </li>
          <li
            className={`mb-[18px] mt-auto block w-full px-2 relative`}
            onClick={() => setOpen(true)}
          >
            <button
              className={`inline-flex items-center justify-center xl:px-5 px-3.5 text-light-grey focus:text-primary-blue hover:text-primary-blue border-0`}
            >
              <IoPower fontSize={"24px"} />
            </button>
          </li>
        </ul>
      </div>
      <Modal open={open} onClose={() => setOpen(false)} className={``}>
        <div className="p-[30px]">
          <span className="flex items-center justify-center mb-6">
            <TbAlertTriangleFilled
              fontSize={"50px"}
              className="text-light-red"
            />
          </span>
          <Paragraph
            className={`mb-30 text-center text-[20px] leading-[26px] font-medium`}
          >
            Are you sure you want to logout?
          </Paragraph>
          <div class="flex items-center justify-center gap-3">
            <Button
              onClick={() => {
                navigate(paths.auth.login);
              }}
              primary
              className={`w-full`}
            >
              Yes
            </Button>
            <Button
              secondary
              onClick={() => setOpen(false)}
              className={`w-full`}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SideBar;
