import React, { useEffect, useRef, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";

function Dropdown({ children, className, dropdownItems, withChevron }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button type="button" className={className} onClick={toggleDropdown}>
        <div className="flex items-center">
          {children}{" "}
          {withChevron ? (
            <TiArrowSortedDown
              size={18}
              className={`transition-all text-dark-grey ${
                isOpen ? "rotate-180" : ""
              }`}
            />
          ) : (
            ""
          )}
        </div>
      </button>

      {isOpen && (
        <div className="border-light-grey-200 border origin-top-right absolute right-0 mt-2 w-full rounded bg-white z-10 overflow-hidden">
          <ul
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {dropdownItems.map((item, index, arr) => (
              <li key={index}>
                <button
                  onClick={() => {
                    closeDropdown();
                    if (item.Link) {
                      // Navigate to the provided link
                      window.location.href = item.Link;
                    }
                    if (item.action) {
                      // Execute the custom action if provided
                      item.action();
                    }
                  }}
                  className={`hover:light-blue-bg py-2 px-3 cursor-pointer transition-all duration-300 ease text-[14px] leading-[18px] font-normal text-site-black w-full text-start flex items-center gap-2 overflow-hidden border-extra-light-blue hover:bg-light-grey-bg ${
                    index === arr.length - 1 ? "" : "border-b"
                  }`}
                >
                  {item.icon && (
                    <item.icon fontSize={16} className="text-dark-grey" />
                  )}
                  {item.LinkName}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
