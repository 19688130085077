import classNames from "classnames";
import React from "react";

function Button({
  children,
  className,
  primary,
  secondary,
  greyBg,
  onlyIcon,
  disabled,
  ...props
}) {
  const classes = classNames(
    "capitalize rounded inline-flex items-center justify-center gap-3 border transition-all duration-700 ease-in-out motion-reduce:transition-none motion-reduce:hover:transform-none text-[16px] leading-[21px] font-semibold pt-[11px] pb-[10px] px-[26px]",
    {
      "bg-primary-blue text-white border-primary-blue hover:bg-site-dark-blue hover:border-site-dark-blue":
        primary,
      "bg-white text-primary-blue border-primary-blue hover:bg-site-dark-blue hover:border-site-dark-blue hover:text-white":
        secondary,
      "bg-light-grey text-site-black": greyBg,
      "w-11 h-11 !px-0 !py-0 border-0": onlyIcon,
      "bg-light-bg text-light-grey-300 border-light-bg": disabled,
    }
  );
  return (
    <button className={`${className ? className : ""} ${classes}`} {...props}>
      {children}
    </button>
  );
}

export default Button;
