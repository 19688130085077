import { lazy } from "react";
import { paths } from "./path";

const Login = lazy(() => import("../pages/authentication/Login"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const CommonElement = lazy(() => import("../pages/CommonElement"));
// const ForgetPassword = lazy(() =>
//   import("../pages/authentication/ForgetPassword")
// );
const UserManagement = lazy(() => import("../pages/UserManagement"));
const ClientAccount = lazy(() =>
  import("../pages/client_account/ClientAccount")
);

export const AuthRoutes = [
  {
    path: paths.auth.login,
    name: "Login",
    element: Login,
  },
  // {
  //   path: paths.auth.forget_password,
  //   name: "Forget Password",
  //   element: ForgetPassword,
  // },
];

export const OtherRoutes = [
  {
    path: paths.web.dashboard,
    name: "Dashboard",
    element: Dashboard,
  },
  {
    path: paths.web.commonelements,
    name: "Common Components",
    element: CommonElement,
  },
  {
    path: paths.web.usermanagement,
    name: "User Management",
    element: UserManagement,
  },
  {
    path: paths.web.clientAccount,
    name: "Client Account",
    element: ClientAccount,
  },
];
