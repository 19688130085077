import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthRoutes, OtherRoutes } from "./routes";
import Wrapper from "../components/Wrapper";

function AppRoutes() {
  return (
    <Routes>
      <Route element={<Wrapper />}>
        {(OtherRoutes || []).map((route, id) => (
          <Route key={id} path={route?.path} element={<route.element />} />
        ))}
      </Route>

      {AuthRoutes.map((route, id) => (
        <Route key={id} path={route?.path} element={<route.element />} />
      ))}
    </Routes>
  );
}

export default AppRoutes;
