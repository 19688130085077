import React, { useState } from "react";
import Logo from "../../assets/images/sitelogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from "../core/form-components/Dropdown";
import userProfile from "../../assets/images/user_profile.png";
import Paragraph from "../core/typography/Paragraph";
import { FaUser } from "react-icons/fa6";
import Modal from "../core/Modal";
import { TbAlertTriangleFilled } from "react-icons/tb";
import Button from "../core/form-components/Button";
import { paths } from "../../routes/path";
import { FaHome } from "react-icons/fa";
import { IoPower } from "react-icons/io5";

function Header() {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownItems = [
    {
      icon: FaUser,
      LinkName: "Profile",
      Link: "",
      action: null,
    },
    {
      icon: IoPower,
      LinkName: "Logout",
      Link: "",
      action: () => setOpen(true),
    },
  ];
  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="md:col-span-4 sm:col-span-5">
        <div className="block">
          <img src={Logo} alt="logo" width="117px" height="46px" />
        </div>
      </div>
      <div className="md:col-span-8 sm:col-span-7">
        <div className="flex items-center justify-end gap-3 ">
          <Dropdown
            className={"flex items-center justify-center"}
            dropdownItems={dropdownItems}
            withChevron
          >
            <div
              className={
                "md:w-[44px] md:h-[44px] sm:w-8 sm:h-8 mr-2 rounded-full bg-[#F7F7FC] overflow-hidden flex items-center justify-center"
              }
            >
              {userProfile ? (
                <img
                  src={userProfile}
                  alt="userprofile"
                  width={44}
                  height={44}
                  className={""}
                />
              ) : (
                <FaUser fontSize={"20px"} />
              )}
            </div>
            <Paragraph text14Semibold className={"text-dark-grey mr-[2px]"}>
              Jay Patel (CEO)
            </Paragraph>
          </Dropdown>
        </div>
      </div>
      <Modal open={open} onClose={() => setOpen(false)} className={``}>
        <div className="p-[30px]">
          <span className="flex items-center justify-center mb-6">
            <TbAlertTriangleFilled
              fontSize={"50px"}
              className="text-light-red"
            />
          </span>
          <Paragraph
            className={`mb-30 text-center text-[20px] leading-[26px] font-medium`}
          >
            Are you sure you want to logout?
          </Paragraph>
          <div class="flex items-center justify-center gap-3">
            <Button
              onClick={() => {
                navigate(paths.auth.login);
              }}
              primary
              className={`w-full`}
            >
              Yes
            </Button>
            <Button
              secondary
              onClick={() => setOpen(false)}
              className={`w-full`}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Header;
