export const paths = {
  auth: {
    login: "/",
    // forget_password: "/forget_password",
  },
  web: {
    dashboard: "/dashboard",
    commonelements: "/commonelements",
    usermanagement: "/usermanagement",
    clientAccount: "/client-account",
  },
};
