import React from "react";
import ReactDOM from "react-dom";
import { CgClose } from "react-icons/cg";
import Paragraph from "./typography/Paragraph";
import { RxCross1 } from "react-icons/rx";

function Modal({ open, onClose, children, header, headerIcon, ...props }) {
  return ReactDOM.createPortal(
    <div
      onClick={onClose}
      className={`fixed inset-0 flex justify-center items-center transition-colors z-[99] ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-white rounded-lg shadow transition-all ${
          headerIcon ? "max-w-[630px]" : "max-w-[430px]"
        } w-full ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}`}
      >
        {header ? (
          <div className="flex items-center justify-between gap-4 mb-4 pt-6 pb-4 px-6 border-b border-extra-light-blue">
            {headerIcon ? (
              <div className="flex items-center justify-center w-[30px] h-[30px] bg-site-black rounded-full">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.31873 8.19742L7.06873 15.2174C7.05538 15.2564 7.05147 15.2981 7.05734 15.3389C7.0632 15.3797 7.07866 15.4185 7.10245 15.4522C7.12625 15.4859 7.15769 15.5134 7.1942 15.5326C7.23071 15.5517 7.27125 15.562 7.31248 15.5624H7.94248C7.99871 15.5623 8.05338 15.5439 8.09832 15.5101C8.14327 15.4763 8.17608 15.4289 8.19185 15.3749L8.54435 14.2499H11.625L11.5819 14.1805L11.9681 15.3749C11.9839 15.4289 12.0167 15.4763 12.0616 15.5101C12.1066 15.5439 12.1612 15.5623 12.2175 15.5624H12.8475C12.8887 15.5627 12.9295 15.5531 12.9663 15.5345C13.0032 15.516 13.0351 15.4889 13.0594 15.4556C13.0838 15.4223 13.0999 15.3836 13.1064 15.3429C13.1129 15.3021 13.1096 15.2604 13.0969 15.2212L10.8469 8.20117C10.8308 8.14735 10.7979 8.1001 10.753 8.06635C10.7082 8.0326 10.6536 8.01414 10.5975 8.01367H9.56248C9.50769 8.01479 9.45465 8.03314 9.41089 8.06613C9.36713 8.09911 9.33488 8.14506 9.31873 8.19742ZM8.91935 13.1249L10.08 9.5118L11.25 13.1249H8.91935Z"
                    fill="#4880FF"
                  />
                  <path
                    d="M15.675 8.01562H15.075C14.93 8.01562 14.8125 8.13315 14.8125 8.27813V15.2981C14.8125 15.4431 14.93 15.5606 15.075 15.5606H15.675C15.82 15.5606 15.9375 15.4431 15.9375 15.2981V8.27813C15.9375 8.13315 15.82 8.01562 15.675 8.01562Z"
                    fill="#4880FF"
                  />
                  <path
                    d="M12.6113 18.375H7.155C6.74922 18.375 6.36006 18.2138 6.07313 17.9269C5.7862 17.6399 5.625 17.2508 5.625 16.845V7.155C5.625 6.74922 5.7862 6.36006 6.07313 6.07313C6.36006 5.7862 6.74922 5.625 7.155 5.625H16.845C17.2508 5.625 17.6399 5.7862 17.9269 6.07313C18.2138 6.36006 18.375 6.74922 18.375 7.155V12C18.3784 12.1023 18.4221 12.1991 18.4966 12.2693C18.571 12.3396 18.6702 12.3775 18.7725 12.375C19.0918 12.374 19.4069 12.4472 19.6931 12.5888C19.7525 12.6192 19.8188 12.6338 19.8855 12.631C19.9522 12.6282 20.0171 12.6082 20.0738 12.5729C20.1304 12.5376 20.177 12.4882 20.2088 12.4295C20.2407 12.3708 20.2568 12.3049 20.2556 12.2381V7.155C20.2556 6.70738 20.1674 6.26415 19.9959 5.85067C19.8244 5.43719 19.5731 5.06157 19.2563 4.74532C18.9396 4.42906 18.5635 4.17836 18.1498 4.00758C17.736 3.83679 17.2926 3.74927 16.845 3.75H7.155C6.25194 3.75 5.38586 4.10874 4.7473 4.74731C4.10874 5.38587 3.75 6.25194 3.75 7.155V16.845C3.75 17.7481 4.10874 18.6141 4.7473 19.2527C5.38586 19.8913 6.25194 20.25 7.155 20.25H13.3988C13.4755 20.2501 13.5505 20.2275 13.6144 20.1849C13.6782 20.1424 13.728 20.0819 13.7575 20.0111C13.787 19.9403 13.7949 19.8623 13.7801 19.787C13.7653 19.7117 13.7285 19.6425 13.6744 19.5881C13.3826 19.3205 13.1463 18.9981 12.9788 18.6394C12.9519 18.563 12.9023 18.4967 12.8366 18.4494C12.7708 18.4022 12.6922 18.3762 12.6113 18.375Z"
                    fill="#4880FF"
                  />
                  <path
                    d="M20.9492 19.0862L22.648 18.4168C22.6829 18.403 22.7129 18.379 22.7341 18.3479C22.7552 18.3168 22.7665 18.28 22.7665 18.2424C22.7665 18.2048 22.7552 18.1681 22.7341 18.137C22.7129 18.1059 22.6829 18.0819 22.648 18.068L20.9492 17.3987C20.6485 17.2798 20.3754 17.1005 20.1467 16.8718C19.9181 16.6431 19.7387 16.37 19.6198 16.0693L18.9505 14.3818C18.9366 14.3469 18.9125 14.317 18.8814 14.2959C18.8503 14.2749 18.8136 14.2636 18.7761 14.2637C18.7385 14.2636 18.7018 14.2749 18.6707 14.2959C18.6397 14.317 18.6156 14.3469 18.6017 14.3818L17.9323 16.0693C17.8137 16.3702 17.6345 16.6435 17.4058 16.8722C17.1771 17.1008 16.9038 17.2801 16.603 17.3987L14.9155 18.068C14.8805 18.0819 14.8505 18.1059 14.8293 18.137C14.8082 18.1681 14.7969 18.2048 14.7969 18.2424C14.7969 18.28 14.8082 18.3168 14.8293 18.3479C14.8505 18.379 14.8805 18.403 14.9155 18.4168L16.603 19.0862C16.9038 19.2048 17.1771 19.384 17.4058 19.6127C17.6345 19.8414 17.8137 20.1147 17.9323 20.4155L18.6017 22.103C18.6153 22.1383 18.6392 22.1686 18.6703 22.19C18.7015 22.2114 18.7383 22.2229 18.7761 22.223C18.8139 22.2229 18.8507 22.2114 18.8818 22.19C18.9129 22.1686 18.9369 22.1383 18.9505 22.103L19.6198 20.4155C19.7387 20.1148 19.9181 19.8417 20.1467 19.613C20.3754 19.3844 20.6485 19.2051 20.9492 19.0862Z"
                    fill="#4880FF"
                  />
                </svg>
              </div>
            ) : (
              <Paragraph text20>{props.headingText}</Paragraph>
            )}
            {/* <Paragraph text20>{props.headingText}</Paragraph> */}
            <button
              onClick={onClose}
              className="text-light-grey hover:text-site-black"
            >
              <RxCross1 fontSize={24} />
            </button>
          </div>
        ) : (
          ""
        )}
        {children}
      </div>
    </div>,
    document.getElementById("modal-root")
  );
}

export default Modal;
